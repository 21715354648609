@media screen and (max-width: 500px) {
  // main {
  //   padding: 0 2rem;
  // }

  .skills-list {
    grid-template-columns: 1fr;
  }

  .tidbits {
    grid-template-columns: repeat(2, 1fr);
    padding: 2vh 0 4vh 0;
  }
  .timeline {
    flex-direction: column;
    margin-left: 10vw;
  }

  @mixin timeline-element-left {
    border-top: none;
    min-height: 50px;
    position: absolute;
    top: 0;
  }

  .timeline-element {
    height: 25px;
    .solid {
      border-left: 3px solid azure;
      @include timeline-element-left;
    }
    .dashed {
      border-left: 3px dashed azure;
      @include timeline-element-left;
    }
  }

  .home {
    padding: 15vh 2vh;
  }

  .experience,
  .skills,
  .projects,
  .trainings,
  .about,
  .contact {
    padding: 5vh 2vh;
  }
  .single-project {
    grid-template-columns: 1fr;
    .mobile {
      max-width: 30%;
      left: 60%;
    }
    .single-project__img {
      min-height: 220px;
    }
    .project-description {
      padding: 2vh 2vh;
      margin-top: 25px;
    }
  }

  .main-nav {
    flex-direction: column;
    height: auto;
    width: 100%;
    background-color: white;
    transition: all 0.3s ease-in-out;
    padding-top: 5rem;
    nav {
      flex-direction: column;
      padding-left: 6rem;
      padding-bottom: 1rem;
      line-height: 2.5rem;
      .main-nav__pipe {
        display: none;
      }
    }
    .lang {
      margin: 0;
      text-align: center;
    }
  }
  .start {
    position: absolute;
    top: -200rem;
  }
  .open {
    position: fixed;
    top: 0;
  }
  .burger-menu {
    //special thanks to codeSTACKr @ YouTube
    z-index: 999;
    position: fixed;
    top: 35px;
    left: 2rem;
    height: 35px;
    display: flex;
    align-items: center;
    backdrop-filter: blur(2px);
    border-radius: 10px;
    .burger-menu-bar {
      width: 55px;
      height: 5px;
      background-color: black;
      border-radius: 50px;
      transition: all 0.3s ease-in;
    }
  }

  .burger-menu-bar::before,
  .burger-menu-bar::after {
    content: '';
    width: 55px;
    height: 5px;
    background-color: black;
    border-radius: 50px;
    position: fixed;
    transition: all 0.3s ease-in;
  }

  .burger-menu-bar::before {
    transform: translateY(-15px);
  }

  .burger-menu-bar::after {
    transform: translateY(15px);
  }
  .burger-menu.open-burger .burger-menu-bar {
    transform: translateX(-50px);
    background: transparent;
  }

  .burger-menu.open-burger .burger-menu-bar::before {
    transform: rotate(45deg) translate(35px, -35px);
  }
  .burger-menu.open-burger .burger-menu-bar::after {
    transform: rotate(-45deg) translate(35px, 35px);
  }

  .tidbit-description {
    p {
      padding: 1rem;
    }
  }

  .tidbit-description-mobile {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 25%;
    z-index: 99;
  }
  .contact {
    .conact-info {
      padding: 10vh 0 10vh 0;
    }
    .contact-email {
      font-size: 2rem;
      padding: 0;
    }
  }
}
