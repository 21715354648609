$lightblue: aliceblue;
$blue: #00d2ff;
$darkblue: #007eff;
$weirdblue: #cad8ff;
$green: #00ffb4;

@mixin section-header {
  text-align: center;
  padding-bottom: 5vh;
}

@import url('https://fonts.googleapis.com/css2?family=Open+Sans&family=Roboto+Condensed&display=swap');

// body {
//   // background-image: linear-gradient(300deg, #00ffb4, #00d2ff);
//   // background-image: linear-gradient(300deg, #00d2ff, #007eff);
// }

main {
  p {
    font-size: 1.4rem;
  }
}

.main-nav {
  display: flex;
  height: 2rem;
  z-index: 99;
  width: 60%;
  left: 50%;
  transform: translateX(-50%);

  nav {
    width: 80%;
    margin: auto;
    display: flex;
    justify-content: space-between;
    font-size: 1.4rem;
    align-items: baseline;
  }
  a {
    font-family: 'Roboto Condensed', sans-serif;
    text-decoration: none;
  }
  .lang {
    margin: auto;

    img {
      width: 2.5rem;
    }
  }
}

.start {
  position: absolute;
  top: 70vh;
}
.scrolled {
  position: fixed;
  top: 0;
  padding: 1vh 200vh;
  background-color: azure;
  border-bottom: 1px solid darkgray;
}

.home {
  padding: 28vh 0 40vh 0;
  background-color: $blue;
  h1 {
    // padding-top: 30vh;
    text-align: center;
  }
  p {
    margin: 5vh auto;
    display: block;
    max-width: 900px;
    text-align: center;
    font-family: 'Open Sans', sans-serif;
  }
  .home__links {
    display: flex;
    justify-content: center;
    .home__links__link {
      display: inline-block;
      img {
        width: 1.5rem;
        padding: 0 0.3rem;
        position: relative;
        top: 1px;
      }
      a {
        font-size: 1.8rem;
        padding-right: 1rem;
        text-decoration: none;
        color: #fff;
        font-weight: bold;
      }
    }
  }
}

.experience {
  padding: 5vh 10vh;
  background-color: $lightblue;
  h2 {
    @include section-header();
  }
}

.employment {
  position: relative;
  margin: 0 10vw;
  * {
    padding: 0;
    margin: 0;
  }
  h4 {
    font-size: 2rem;
  }
  h5 {
    font-size: 1.2rem;
  }
  h6 {
    color: $darkblue;
    font-size: 1.4rem;
  }
  ul {
    padding: 2vh;
  }
}

.employment::before,
.single-project::before {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  left: -2vh;
  width: 4px;
  height: 100px;
  background-color: $blue;
}

.skills {
  padding: 5vh 10vh;
  background-color: $weirdblue;
  h2 {
    @include section-header();
  }
}

#tools {
  padding-top: 5vh;
}
.skills-list {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  article {
    border: 1px solid darkgray;
    border-radius: 10px;
    padding: 0 1rem;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    p {
      font-size: 1rem;
    }
    h3 {
      display: inline-block;
    }
    img {
      max-width: 100%;
      height: auto;
      display: block;
      margin: auto;
    }
    .skill-icon {
      display: inline-block;
      width: 25px;
      position: relative;
      top: 5px;
      padding-right: 5px;
    }
  }
}

.projects {
  padding: 5vh 10vh;
  background-color: $lightblue;
  h2 {
    @include section-header();
  }
}

.projects-nav {
  display: flex;
  justify-content: space-around;
  margin-bottom: 1rem;
  #active {
    color: white;
    background-color: #007eff;
    padding: 1rem;
  }
  #inactive {
    color: #007eff;
    padding: 1rem;
    text-decoration: underline;
  }
}

.single-project {
  position: relative;
  min-height: 45vh;
  display: grid;
  grid-template-columns: 40% 60%;
  .desktop {
    top: 0%;
    width: 100%;
  }
  .mobile {
    position: absolute;
    max-width: 150px;
    top: 5vw;
    left: 25%;
  }
  .single-project__img {
    margin-bottom: 4rem;
  }
  .project-description {
    padding: 0 10vh;
    h3 {
      margin: 0;
      font-size: 1.4rem;
    }
    .project-description__tag {
      background-color: azure;
      border: 1px solid darkgray;
      color: $darkblue;
      display: inline-block;
      font-size: 0.7rem;
      min-width: 50px;
      padding: 2px;
      margin: 5px 5px 5px 0;
      text-align: center;
    }
    .project-description__link {
      display: flex;
      align-items: flex-end;
      img {
        height: 1rem;
        margin: 5px;
      }
      a {
        text-decoration: none;
        font-size: 1.4rem;
      }
    }
  }
}

.trainings {
  padding: 5vh 10vh;
  background-color: $darkblue;
  color: azure;
  h2 {
    @include section-header();
  }
}

.legend p {
  display: inline-block;
  padding-left: 3vw;
  margin: 0;
  font-size: 1rem;
}

.soon {
  border-top: 3px solid azure;
  width: 120px;
  height: 3px;
  display: inline-block;
}

.later {
  border-top: 3px dashed azure;
  width: 120px;
  height: 3px;
  display: inline-block;
}

.timeline {
  display: flex;
  margin-top: 5vh;
}

.timeline-element {
  display: inline-block;
  height: 30px;
  position: relative;
  padding-top: 25px;
  width: 100%;
  .solid {
    border-top: 3px solid azure;
  }

  .dashed {
    border-top: 3px dashed azure;
  }
  .angle {
    border-left: 3px solid azure;
    height: 20px;
    width: 50px;
    transform: rotate(45deg);
    position: relative;
    bottom: -21px;
  }
  .under {
    border-top: 3px solid azure;
    width: 100px;
    height: 20px;
    position: absolute;
    top: 30px;
    left: 15px;
  }
  .subject {
    position: absolute;
    top: 8px;
    left: 25px;
  }
}

.about {
  padding: 5vh 10vh;
  background-color: $blue;
  h2 {
    @include section-header();
  }
}

.tidbits {
  max-width: 1200px;
  margin: auto;
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  gap: 20px;
  justify-content: space-between;
}

.tidbit {
  display: flex;
  margin: auto;
  width: 12vh;
  height: 12vh;
  overflow: hidden;
  text-align: center;
  border-radius: 10px;
  position: relative;
  justify-content: center;
  img {
    display: block;
    margin: 10px auto;
    width: 70%;
    height: 70%;
  }
  p {
    position: absolute;
    bottom: -10px;
    color: white;
    font-weight: bold;
    font-size: 0.8rem;
    text-shadow: 0px 0px 5px black;
    padding: 0 1rem;
    line-height: 16px;
  }
}

.tidbit-description {
  min-height: 100px;
  background-color: $lightblue;
  margin-top: 5vh;
  border-radius: 5px;
  p {
    margin: 1rem 3rem;
    padding: 2rem;
  }
}

.contact {
  padding: 5vh 10vh;
  h2 {
    @include section-header();
  }
  background-color: $lightblue;
  text-align: center;
  .contact-info {
    padding: 20vh 0 28vh 0;
  }
  .contact-email {
    font-size: 3rem;
    padding: 2rem;
    a {
      text-decoration: none;
      font-weight: bold;
      font-family: inherit;
      color: $darkblue;
    }
  }
}

footer {
  text-align: center;
}
